<!--
Display bookmarks found in ScrapFNUsers/{uid}/bookmarks
Please display each object using MapCard.vue component
-->
 
<template>
    <div>
        <b-card-group deck>
            <map-card v-for="(bookmark, index) in bookmarks" :key="index" :map="bookmark" details />
        </b-card-group>
    </div>
</template>

<script>
import { getLog } from '@/services/log'
import { db } from '@/services/db'

const log = getLog('Bookmarks')

export default {
    name: 'Bookmarks',
    components: {
        MapCard : () => import('./components/MapCard.vue')
    },
    data() {
        return {
            bookmarks: []
        }
    },
    async created() {
        try {
            const bookmarksRef = db.collection(`ScrapFNUsers/${this.$store.account.uid}/bookmarks`)
            const bookmarksSnapshot = await bookmarksRef.get()
            bookmarksSnapshot.forEach((doc) => {
                this.bookmarks.push(doc.data())
            })
        } catch (error) {
            log.error(error)
        }
    }
}
</script>
